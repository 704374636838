
















































import { computed, ref } from '@vue/composition-api';

export default {
  props: {
    internshipStart: {
      type: String,
      default: '2022-07-01'
    },
    internshipEnd: {
      type: String,
      default: '2024-06-01'
    }
  },
  setup(props, ctx) {
    const date1 = ref('');
    const modal1 = ref('');
    const date2 = ref('');
    const modal2 = ref('');
    const internshipStartDate = ref();
    const internshipEndDate = ref();
    const internshipStartVal = computed({
      get: () => props.internshipStart,
      set: newVal => {
        internshipStartDate.value = newVal;
      }
    });
    internshipStartDate.value = internshipStartVal.value;
    const internshipEndVal = computed({
      get: () => props.internshipEnd,
      set: newVal => {
        internshipEndDate.value = newVal;
      }
    });
    internshipEndDate.value = internshipEndVal.value;
    return { internshipStartDate, internshipEndDate, date1, modal1, date2, modal2 };
  }
};
